.title-container {
  height: 160px;
  background: center / cover no-repeat url(../../assets/title-bg@2x.png);
  display: flex;
  align-items: center;
  justify-content: center;
  .title-txt {
    padding-left: 24px;
    width: 1200px;
    height: 50px;
    font-size: 36px;
    font-weight: 500;
    color: #303133;
    line-height: 50px;
  }
}
